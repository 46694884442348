import React from 'react';

const ViewForm = (props) => {

  return (
    <>
      <div className="modal fade" id="viewForm" >
        <div className="modal-dialog modal-xl" >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Print Applicant Voucher   <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">x</span>
              </button></h4>

            </div>
            <div className="modal-body text-align-center" >
              <p className='container'>
                Record of {JSON.stringify(props?.applicationDetails, null, 2)}
              </p>

              <br />
            </div></div></div>


      </div>

    </>)
}

export default ViewForm