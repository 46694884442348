import React, { useEffect, useState } from "react";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";


const Page = () => {
  const [applicant, setApplicant] = useState({
    name: "",
    fname: "",
    gender: "",
    birthday: "",
    cnic: "",
    fcnic: "",
    contact1: "",
    contact2: "",
    domicile: "",
    religion: "",
    attemptStatus: '',
    attempt: "No",
    obtainedMarks: "0",
    currentClass: "",
    testCity: "",
    presentAddress: "",
    permanentAddress: "",
    hostel: "",
    class1: "",
    class1address: "",
    class2: "",
    class2address: "",
    class3: "",
    class3address: "",
    class4: "",
    class4address: "",
    class5: "",
    class5address: "",
    class6: "",
    class6address: "",
    pref1: "",
    pref2: "",
    pref3: "",
    pref4: "",
    pref5: ""

  });
  const [step1, setStep1] = useState(true);
  const [step2, setStep2] = useState(false);
  const [step3, setStep3] = useState(false);
  // const [step4, setStep4] = useState(false);

  return (
    <>
      {/* {JSON.stringify(applicant, null, 2)} */}

      {step1 && <Page1
        applicant={applicant}
        setApplicant={setApplicant}
        step1={step1}
        step2={step2}
        step3={step3}
        setStep1={setStep1}
        setStep2={setStep2}
        setStep3={setStep3}
      />}
      {step2 && <Page2
        applicant={applicant}
        setApplicant={setApplicant}
        step1={step1}
        step2={step2}
        step3={step3}
        setStep1={setStep1}
        setStep2={setStep2}
        setStep3={setStep3}
      />}
      {step3 && <Page3
        applicant={applicant}
        setApplicant={setApplicant}
        step1={step1}
        step2={step2}
        step3={step3}
        setStep1={setStep1}
        setStep2={setStep2}
        setStep3={setStep3}
      />}


    </>

  )
}

export default Page