import React from 'react';
import {
  Text,
  Font,
  Page,
  View,
  Image,
  Document,
  StyleSheet,
} from '@react-pdf/renderer';
import UOPLogo from "../etea-logo.png";
import easyPaisaLogo from "../easypaisa.png";
import eSahulatLogo from "../esahulat.png"
import omniLogo from "../omni.png";
import ublLogo from "../ubl.png";
import jazzCash from "../jazzcash.png";

const GenerateVoucher = (props) => {
  const slipCopies = ["Bank Copy", "Candidate Copy"];
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row', // Make the page a horizontal row
      padding: 10,
    },
    text: {
      marginLeft: 2,
      marginBottom: 5,
      fontSize: 10,
    },
    textBold: {
      marginTop: 8,
      marginLeft: 2,
      fontSize: 10,
      fontWeight: 'bold'
    },
    textsignature: {
      marginTop: 50,
      fontSize: 12,
      marginLeft: 5
    },
    header: {
      textAlign: 'center',
      marginTop: 5,
      marginLeft: 50,
      fontSize: 8,
      color: '#006837',
      fontWeight: 'bold',
    },
    header2: {
      textAlign: 'center',
      marginTop: 5,
      fontSize: 7,
      color: '#78990E',
    },
    subheader: {
      // textAlign: 'center',
      marginTop: 5,
      fontSize: 10,
      marginBottom: 5

    },
    centerSubHeader: {
      textAlign: 'center',
      marginTop: 5,
      fontSize: 10,
      marginBottom: 5,
      fontWeight: 'bold'

    },
    centerSubHeaderRed: {
      textAlign: 'center',
      fontSize: 7,
      marginBottom: 5,
      fontWeight: 'bold',
      color: 'red'

    },

    // section: {
    //   border: 1,
    //   display: ' block',
    //   height: '800px',
    //   width: '200px',
    //   marginBottom: 2,

    // },
    section: {
      width: '50%', // Divide page into two sections (50% width for each)
      padding: 10,
      height: '800px', // Adjust height as needed
    },
    row: {
      flex: 1,
      flexDirection: 'row',
      flexGrow: 1,
    },
    left: {
      // width: '33%',//<- working alternative
      marginLeft: 5,
      textAlign: 'left',
      fontSize: 10

    },
    boldtext: {
      marginTop: 8,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: 8,
      textDecoration: 'underline',
      marginLeft: 2,
      color: 'red'
    },
    note: {
      fontWeight: 'bolder',
      fontSize: 13,
      margin: 5,
    },
    imageLeft: {
      position: 'absolute',
      top: '10',
      left: '10',
      width: 40,
      height: 46,
    },
    // tableLogo: {
    //   position: 'absolute',
    //  width: 38,
    //  height: 47,
    // },
    tableLogo: {
      width: 20, // Set width to auto to maintain the image's intrinsic width
      height: 20, // Set height to auto to maintain the image's intrinsic height
      // maxWidth: '50%', // Limit the maximum width to prevent overflow
      // maxHeight: '50%', // Limit the maximum height to prevent overflow
      padding: 3
    }
    ,
    tableLogoEP: {
      width: 50, // Set width to auto to maintain the image's intrinsic width
      height: 20, // Set height to auto to maintain the image's intrinsic height
      // maxWidth: '50%', // Limit the maximum width to prevent overflow
      // maxHeight: '50%', // Limit the maximum height to prevent overflow
      padding: 3
    }
    ,
    tableLogoUBL: {
      width: 80, // Set width to auto to maintain the image's intrinsic width
      height: 25, // Set height to auto to maintain the image's intrinsic height
      // maxWidth: '50%', // Limit the maximum width to prevent overflow
      // maxHeight: '50%', // Limit the maximum height to prevent overflow
      padding: 3
    }
    ,
    imageRight: {
      position: 'absolute',
      top: '15',

      right: '10',
      width: 50,
      height: 30,
    },
    newLabel: {
      textAlign: 'center',
      marginTop: 5,
      marginLeft: 100,
      padding: 3,
      width: 90,
      fontSize: 10,
      fontWeight: 'bold',
      color: '#fff',
      backgroundColor: '#000'
    },
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 10,
    },
    table: {
      // display: "table",
      width: '48%',
      borderStyle: "solid",
      borderWidth: 1,
      // borderRightWidth: 0, 
      // borderBottomWidth: 0,
      marginTop: 3,


    },
    tableFull: {
      // display: "table",
      width: '99%',
      borderStyle: "solid",
      borderWidth: 1,
      // borderRightWidth: 0, 
      // borderBottomWidth: 0,
      marginTop: 3,


    },

    tableRow: {
      margin: "auto",
      flexDirection: "row",
      textAlign: 'left',

    },
    tableRow50: {
      // width: '95%',
      borderStyle: "solid",
      borderWidth: 1,
      margin: 1,
      // flexDirection: "row",
      textAlign: 'left',


    },
    tableCol2: {
      width: "100%",
      borderStyle: "solid",
      // borderWidth: 1, 
      padding: 3,
      // fontSize: 8,
      padding: 3,
      flexDirection: 'row', // Align children horizontally
      alignItems: 'center', // Center items vertically
      justifyContent: 'space-between', // Align children to both ends

      // borderLeftWidth: 0, 
      // borderTopWidth: 0 
    },
    tableCol50: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0
    },
    tableCell: {
      //margin: "auto", 
      // margin: 2, 
      fontSize: 9,
      fontWeight: 'bold',
      padding: 1,
      height: 15

    },
    tableCellSmall: {
      //margin: "auto", 
      // margin: 2, 
      fontSize: 7,
      fontWeight: 'bold',
      padding: 1,

    },
    tableCellBold: {
      fontWeight: 'bold',
      fontSize: 9,
      padding: 2,
      color: '#ed8c26',

    },
    checkbox: {
      width: 12,
      height: 12,
      borderWidth: 1,
      borderColor: 'black',
      alignItems: 'center',
      justifyContent: 'right',
      marginLeft: 10,
    },
    checkmark: {
      fontSize: 8,
    },
  });
  // Font.register({
  //   family: 'Oswald',
  //   src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  // });
  const projectTitleLength = 45;
  const projectName = props?.applicant?.project_name || ''; // Ensure project_name is not null or undefined

  // Truncate or pad projectName to fit within 60 characters
  const adjustedProjectName = projectName.length <= projectTitleLength
    ? projectName.padEnd(projectTitleLength, '-')
    : projectName.slice(0, projectTitleLength);

  const lastDateLength = 30;
  const lastDate = props?.applicant?.project_expiry_date || ''; // Ensure project_name is not null or undefined
  // const projectName = '1234567891223456789123456789012345678991234567890123456789' || ''; // Ensure project_name is not null or undefined

  // Truncate or pad projectName to fit within 60 characters
  const adjustedLastDate = lastDate.length <= lastDateLength
    ? lastDate.padEnd(lastDateLength, '-')
    : lastDate.slice(0, lastDateLength);

  const inputDate = new Date(lastDate);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const formattedDate = inputDate.toLocaleDateString('en-US', options);




  return (
    <Document title='Fee Deposit Slip' author='ETEA' subject='Fee Deposit Slip' pdfVersion >
      <Page style={styles.page} wrap size="A4">

        {slipCopies?.map((pro) =>
          <View style={styles.section}>
            <Image style={styles.imageLeft} src={UOPLogo} />
            <Text style={styles.header}>EDUCATIONAL TESTING AND EVALUATION AGENCY </Text>
            <Text style={styles.header2}>GOVERNMENT OF KHYBER PAKHTUNKHWA </Text>
            <Text style={styles.newLabel}>{pro}</Text>
            <Text style={styles.subheader}>
              Branch Code: ______________     Date: _______________ </Text>
            <Text style={styles.subheader}>
              Branch Name: ____________________________________ </Text>
            <Text style={styles.centerSubHeader}>
              ONLINE DEPOSIT SLIP</Text>
            <Text style={styles.centerSubHeaderRed}>
              (* PLEASE DEPOSIT FEE IN ONLY ONE BANK)</Text>

            <View style={styles.tableFull}>

              <View style={styles.tableRow50}>
                <View style={styles.tableCol2}>
                  <Image style={styles.tableLogoUBL} src={ublLogo} />

                  {/* <Text style={styles.tableCellBold}> United Bank Limited  </Text>  */}
                  <View style={styles.checkbox}>
                    <Text style={styles.checkmark}> </Text>
                  </View>
                </View>
              </View>
              <View style={styles.tableRow50}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>A/C Title: ETEA</Text>
                </View>
              </View>
              <View style={styles.tableRow50}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCell}>A/C No: 254731873</Text>
                </View>
              </View>
              <View style={styles.tableRow50}>
                <View style={styles.tableCol2}>
                  <Text style={styles.tableCellSmall}>Note: Bank Service Charges free of Cost</Text>
                </View>
              </View>





            </View>
            <View style={styles.container}>

              <View style={styles.table}>

                <View style={styles.tableRow50}>


                  <View style={styles.tableCol2}>
                    <Image style={styles.tableLogo} src={eSahulatLogo} />
                    <Text style={styles.tableCellBold}> Nadra ESahulat  </Text>
                    <View style={styles.checkbox}>
                      <Text style={styles.checkmark}> </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>A/C Title: ETEA</Text>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Consumer No: {props?.applicant?.consumer_no}</Text>
                  </View>
                </View>






              </View>
              <View style={styles.table}>

                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Image style={styles.tableLogoEP} src={easyPaisaLogo} />

                    {/* <Text style={styles.tableCellBold}>Easy Paisa</Text>  */}
                    <View style={styles.checkbox}>
                      <Text style={styles.checkmark}> </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>A/C Title: ETEA</Text>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Consumer No: {props?.applicant?.consumer_no}</Text>
                  </View>
                </View>






              </View>
            </View>
            <View style={styles.container}>

              <View style={styles.table}>

                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Image style={styles.tableLogoEP} src={omniLogo} />

                    {/* <Text style={styles.tableCellBold}>UBL Omni</Text>  */}
                    <View style={styles.checkbox}>
                      <Text style={styles.checkmark}> </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>A/C Title: ETEA</Text>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Consumer No: {props?.applicant?.consumer_no}</Text>
                  </View>
                </View>





              </View>
              <View style={styles.table}>

                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Image style={styles.tableLogoEP} src={jazzCash} />

                    {/* <Text style={styles.tableCellBold}>Jazz Cash</Text>  */}
                    <View style={styles.checkbox}>
                      <Text style={styles.checkmark}> </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>A/C Title: ETEA</Text>
                  </View>
                </View>
                <View style={styles.tableRow50}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Consumer No: {props?.applicant?.consumer_no}</Text>
                  </View>
                </View>






              </View>
            </View>

            <Text style={styles.textBold}>
              Project Title: {projectName}
            </Text>
            <Text style={styles.textBold}>
              Last date for fee submission: {formattedDate}
            </Text>
            <Text style={styles.boldtext}>
              Do not deposit the fee after the last date mentioned on the challan  </Text>

            <View style={styles.container}>
              <View style={styles.tableFull}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Project ID: {props?.applicant?.project_id}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.container}>
              <View style={styles.tableFull}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Applicant Name: {props?.applicant?.name}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.container}>
              <View style={styles.tableFull}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>CNIC No/ Form-B: {props?.applicant?.cnic}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.container}>
              <View style={styles.tableFull}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Amount: Rs. 500/- </Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={styles.container}>
              <View style={styles.tableFull}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol2}>
                    <Text style={styles.tableCell}>Amount in Words: Five Hundred Only/-</Text>
                  </View>
                </View>
              </View>
            </View>
            <Text style={styles.textBold}></Text>
            <Text style={styles.textBold}></Text>
            <Text style={styles.textBold}></Text>
            <Text style={styles.textBold}></Text>
            <Text style={styles.textBold}></Text>

            <Text style={styles.textBold}>__________________     ___________    ___________</Text>
            <Text style={styles.textBold}>Applicant Signature               Cashier              Officer</Text>

            <Text style={styles.boldtext}>
              Please do not send this slip to the ETEA office. Keep this slip safe with you  </Text>
          </View>)}





      </Page>
    </Document>

  )
}

export default GenerateVoucher
