import React from 'react'
import Logo from '../etea-logo.svg'
import { Link } from "react-router-dom";


const Header = () => {
  return (
    <header className="etea-online-header">
      <div className="col-xs-12 col-sm-12 hidden-xs hidden-sm col-md-2 col-lg-3 p-l-r-o">&nbsp;</div>
      <div className="col-xs-12 col-sm-3 col-md-1 col-lg-1 p-l-r-o">
        <img src={Logo} className="etea-logo" />
      </div>

      <div className="col-xs-12 col-sm-9 col-md-8 col-lg-6">
        <div className="etea-online-main-heading" style={{ fontWeight: 500 }}>Educational Testing &amp; Evaluation Agency (ETEA)</div>
        <div className="etea-online-sub-main-heading" style={{ fontWeight: 500 }}>Government of Khyber Pakhtunkhwa</div>
      </div>
      <div className="col-xs-12 col-sm-12 hidden-xs hidden-sm col-md-2 col-lg-2 p-l-r-o btn-header">
        {<input className="btn btn-primary " type="submit" value="Home Page" />}
        {/* <Link to="/search" className="btn btn-primary btn-style btn-large">Already Applied</Link> */}
      </div>
    </header>


  )
}

export default Header