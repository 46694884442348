import React from 'react'

const Footer = () => {
  return (
    <footer className="page-footer hidden-xs">
      <div className="container">
        <div className="row">
          <center>
            <h5 className="black-text">ETEA Contact Info</h5>
          </center>
          <div className="col-sm-12 contact-info">
            <div className="col-sm-4 p-l-r-0">
              <div className="footer-info-contact">
                <i className="fa fa-phone" />
                <h3>Phone</h3>
                <span><a href="tel:+92 091 9219049">(+92 91) 9219131</a></span>&nbsp;&nbsp;
                <span><a href="tel:+92 091 9219049">9219132</a></span>&nbsp;&nbsp;
                <span><a href="tel:+92 091 9219078">9219133</a></span>&nbsp;&nbsp;
                <span><a href="tel:+92 091 9219049">9219049</a></span>
              </div>
            </div>
            <div className="col-sm-4 p-l-r-0">
              <div className="footer-info-contact">
                <i className="fa fa-envelope" />
                <h3>Email</h3>
                <span><a href="mailto:info@etea.edu.pk">info@etea.edu.pk</a></span>
              </div>
            </div>
            <div className="col-sm-4 p-l-r-0">
              <div className="footer-info-contact">
                <i className="fa fa-map-marker" />
                <h3>Address</h3>
                <span><a href="https://etea.online/apply/login/contact">Plot No.22, Street No.13, Sector E-8, Hayatabad Phase-7, Peshawar</a></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>


  )
}

export default Footer