import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Body from './components/Body';
import Header2 from './components/Header2';
import Footer from './components/Footer';
import Page from './components/Page';
import Search from './components/Search';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Routes>
          <Route exact path="/" element={<Body />} />
          <Route exact path="/new" element={<Page />} />
          <Route exact path="/search" element={<Search />} />
        </Routes>
      </Router>
      {/* <Body /> */}
      {/* <Page /> */}

      <Footer />
    </div>
  );
}

export default App;
