import React from 'react'
import { Link, useNavigate } from "react-router-dom";

const Body = () => {
  return (
    <div className="container" style={{ minHeight: '300px' }}>
      <div className="col-xs-12 col-sm-12 col-md-12" style={{ marginBottom: 15 }}>
        <div>
          <h6 className="page-heading">Application Form for Scholarship/Admission in Class 7th in Centers of Excellence</h6>
          <p className="page-sub-heading">Under the scheme provision of Free &amp; Quality Education for Talented Students of Government Schools of Khyber Pakhtunkhwa</p></div>
      </div>

      <div className="col-xs-12 home-page-buttons" id="myDiv" style={{ margin: 25 }}>
        <div class="col-md-10 col-lg-9 col-lg-offset-3 col-md-offset-2 hidden-xs hidden-sm">
          <Link to="/search" className="btn btn-primary btn-style btn-large">Already Applied</Link>
          <label className="new-urdu">اگر آپ نے درخواست فارم پُر کر لیا ہے تو اس بٹن پر کلک کریں</label><br /><br />
          <Link to="new" className="btn btn-primary btn-style btn-large"  >&nbsp;New Applicant &nbsp;</Link>
          <label className="new-urdu" >اگر آپ نے درخواست فارم پُر نہیں کیا ہے تو اس بٹن پر کلک کریں</label>
        </div>

        <div class="hidden-md hidden-lg">
          <label className="new-urdu">اگر آپ نے درخواست فارم پُر کر لیا ہے تو اس بٹن پر کلک کریں</label><br />
          <Link to="/search" className="btn btn-primary btn-style btn-large">Already Applied</Link><br /><br />
          <label className="new-urdu" >اگر آپ نے درخواست فارم پُر نہیں کیا ہے تو اس بٹن پر کلک کریں</label><br />
          <Link to="new" className="btn btn-primary btn-style btn-large" >New Applicant</Link>
        </div>
      </div>
    </div>
  )
}

export default Body